import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, useRef} from 'react';
import {AdaptiveModalRef} from '../../components/templates';
import {useSafelyGoBack} from '../hooks';
import {RootStackBindingProps} from './RootStackBindingProps';
import {
  WorkerGroupAction,
  WorkerGroupActionModal,
} from '../../WorkerGroupActionModal';
import {useRoot, useStrings} from '../../Root/hooks';
import {expr} from 'mobx-utils';
import {SlotStatus} from '../../../universal/features/api/entity/dashboard/worker/WorkerEntity';

export type WorkerGroupActionBindingProps =
  RootStackBindingProps<'WorkerGroupAction'>;

export default observer(function WorkerGroupActionBinding({
  navigation,
  route,
}: WorkerGroupActionBindingProps) {
  const {
    dashboardStore: {groups},
    currentSubscriptionState,
    flashMessages,
  } = useRoot();
  const strings = useStrings();
  const {isPlatformSupported} = currentSubscriptionState;
  const groupId = route.params?.groupId;
  const group = expr(() =>
    groupId !== undefined ? groups.get(groupId) : undefined,
  );
  const isDefaultGroup = expr(() => groupId === 0);
  const safelyGoBack = useSafelyGoBack();
  const modalRef = useRef<AdaptiveModalRef>(null);
  const close = useCallback(() => modalRef.current?.close(), []);
  const onAction = useCallback(
    async (action: WorkerGroupAction) => {
      switch (action) {
        case WorkerGroupAction.Delete:
          if (group && group.workers.length > 0) {
            flashMessages.showMessage({
              title: strings['deleteMinersgroup.message'],
              variant: 'danger',
            });
            return;
          }
      }

      await close();
      navigation.pop(1);
      if (group === undefined) {
        return;
      }
      switch (action) {
        case WorkerGroupAction.AddSlots:
          return navigation.navigate('Plan');
        case WorkerGroupAction.Schedule:
          return navigation.navigate('Schedule', {
            ownerName: group.name,
            workerIds: group.workers.map((_) => _.id),
          });
        case WorkerGroupAction.Rename:
          return navigation.navigate('RenameWorkerGroup', {
            groupId: group.id,
          });
        case WorkerGroupAction.Statistics:
          return navigation.navigate('Root', {
            screen: 'Statistics',
            params: {groupId: group.id},
          });
        case WorkerGroupAction.Delete:
          return navigation.navigate('DeleteWorkerGroupConfirm', {
            groupId: group.id,
          });
      }
    },
    [close, flashMessages, group, navigation, strings],
  );
  const scheduleDisabled = expr(
    () =>
      group?.workers.length === 0 ||
      group?.workers.some((_) => _.slot_status === SlotStatus.Inactive),
  );
  const disabledList = useMemo(() => {
    let list: WorkerGroupAction[] = [];
    if (scheduleDisabled) {
      list.push(WorkerGroupAction.Schedule);
    }
    if (!isPlatformSupported) {
      list = [
        ...list,
        WorkerGroupAction.AddSlots,
        WorkerGroupAction.Rename,
        WorkerGroupAction.Statistics,
        WorkerGroupAction.Delete,
      ];
    }
    return list;
  }, [isPlatformSupported, scheduleDisabled]);
  return (
    <WorkerGroupActionModal
      ref={modalRef}
      onModalClosed={safelyGoBack}
      onAction={onAction}
      disabledList={disabledList}
      isDefaultGroup={isDefaultGroup}
    />
  );
});
