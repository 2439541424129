import React, {forwardRef} from 'react';
import {observer} from 'mobx-react-lite';
import {AdaptiveModalRef} from '../components/templates';
import AddSlotSvg from '../assets/svg/colorless/addSlot.svg';
import ScheduleSvg from '../assets/svg/colorless/schedule.svg';
import SegmentSvg from '../assets/svg/colorless/segment.svg';
import EditSvg from '../assets/svg/colorless/edit.svg';
import TrashSvg from '../assets/svg/colorless/trash.svg';
import {sized} from '../Svg';
import {AdaptiveActionListModal, ActionVariant} from '../components/organisms';
import {useStrings} from '../Root/hooks';

export enum WorkerGroupAction {
  AddSlots,
  Schedule,
  Rename,
  Statistics,
  Delete,
}

export type WorkerGroupActionModalProps = {
  onModalClosed: () => void;
  onAction: (action: WorkerGroupAction) => void;
  disabledList: WorkerGroupAction[];
  isDefaultGroup: boolean;
};

export default observer<WorkerGroupActionModalProps, AdaptiveModalRef>(
  forwardRef(function WorkerGroupActionModal(
    {onAction, disabledList, isDefaultGroup, ...rest},
    ref,
  ) {
    const strings = useStrings();
    const actionList = [
      {
        title: strings['workerGroup.action.addMoreSlots'],
        onPress: () => onAction(WorkerGroupAction.AddSlots),
        Icon: AddSlot,
        disabled: disabledList.includes(WorkerGroupAction.AddSlots),
      },
      {
        title: strings['workerGroup.action.schedule'],
        onPress: () => onAction(WorkerGroupAction.Schedule),
        Icon: ScheduleIcon,
        disabled: disabledList.includes(WorkerGroupAction.Schedule),
      },
      {
        title: strings['action.rename'],
        onPress: () => onAction(WorkerGroupAction.Rename),
        Icon: EditIcon,
        disabled: disabledList.includes(WorkerGroupAction.Rename),
      },
      {
        title: strings['action.statistics'],
        onPress: () => onAction(WorkerGroupAction.Statistics),
        Icon: SegmentsIcon,
        disabled: disabledList.includes(WorkerGroupAction.Statistics),
      },
      ...(!isDefaultGroup
        ? [
            {
              title: strings['action.delete'],
              onPress: () => onAction(WorkerGroupAction.Delete),
              Icon: TrashIcon,
              variant: ActionVariant.Error,
              disabled: disabledList.includes(WorkerGroupAction.Delete),
            },
          ]
        : []),
    ];
    return (
      <AdaptiveActionListModal actionList={actionList} {...rest} ref={ref} />
    );
  }),
);

const AddSlot = sized(AddSlotSvg, 13);
const ScheduleIcon = sized(ScheduleSvg, 15);
const SegmentsIcon = sized(SegmentSvg, 15);
const EditIcon = sized(EditSvg, 16);
const TrashIcon = sized(TrashSvg, 16);
