import {useIsFocused} from '@react-navigation/native';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useRoot} from '../../Root/hooks';
import {StatisticsScreen} from '../../StatisticsScreen';
import {BottomTabBindingProps} from './BottomTabBindingProps';
import {useSafelyGoBack} from '../hooks';
import {RatesPanelState} from '../../RatesPanel';

export type StatisticBindingProps = BottomTabBindingProps<'Statistics'>;

export enum StatisticsTabKey {
  Mining,
  BTC,
}

export default observer(function StatisticBinding({
  navigation,
  route,
}: StatisticBindingProps) {
  const {groupId} = route.params ?? {};
  const root = useRoot();
  const {dashboardStore} = root;
  const group = dashboardStore.dashboardRequest.value?.groups.find(
    (_) => _.id === groupId,
  );
  const safelyGoBack = useSafelyGoBack();
  const isFocused = useIsFocused();
  useLayoutEffect(() => {
    if (groupId !== undefined && group === undefined) {
      safelyGoBack();
    }
  }, [group, groupId, safelyGoBack]);
  const [state] = useState(() => new RatesPanelState(root));
  const onRefresh = useCallback(() => state.refresh(), [state]);
  useEffect(() => {
    if (isFocused) {
      // noinspection JSIgnoredPromiseFromCall
      state.fetch();
    }
  }, [isFocused, state]);

  const goToPlan = useCallback(() => navigation.navigate('Plan'), [navigation]);
  const goToAddDemoMiner = useCallback(
    () => navigation.navigate('AddDemoWorker'),
    [navigation],
  );

  const btcInUsd =
    dashboardStore.currentRateRequest.value &&
    Math.round(
      dashboardStore.currentRateRequest.value?.usd /
        dashboardStore.currentRateRequest.value?.btc,
    );

  return (
    <StatisticsScreen
      onRefresh={onRefresh}
      btcInUsd={btcInUsd}
      ratesState={state}
      goToPlan={goToPlan}
      goToAddDemoMiner={goToAddDemoMiner}
    />
  );
});

export type BalanceType = {
  approxUsd: string | null;
  btc: string | null;
};
