import {Location} from './Location';
import {Url} from '../units';
import {Either, error, success} from '../fp';
import {LinkingOptionsProvider} from '../LinkingOptionsProvider';
import {NavigationContainer} from '../NavigationContainer';
import {getActionFromState, getStateFromPath} from '@react-navigation/core';
import {ParamListBase} from '@react-navigation/routers';
import {NavigationProp} from '@react-navigation/core/src/types';
import {PREFIXES} from '../LinkingOptionsProvider/constant';
import HeadlessLocationImpl from './HeadlessLocationImpl';
import {SpecialLocation} from '../SpecialLocation';
import {EXTERNAL} from '../SpecialLocation/constants';

export default class WebLocationImpl
  extends HeadlessLocationImpl
  implements Location
{
  constructor(
    private readonly _root: {
      readonly navigationContainer: NavigationContainer;
      readonly linkingOptionsProvider: LinkingOptionsProvider;
      readonly specialLocation: SpecialLocation;
    },
  ) {
    super();
  }

  private static _removePrefix(locator: Url): Url {
    for (const prefix of PREFIXES) {
      if (locator.startsWith(prefix)) {
        return locator.replace(prefix, '') as Url;
      }
    }
    return locator;
  }

  async open(_locator: Url): Promise<Either<void, unknown>> {
    if (this._root.specialLocation.parse(_locator).kind === EXTERNAL) {
      return super.open(_locator);
    }

    const locator = WebLocationImpl._removePrefix(_locator);

    if (!locator.startsWith('/')) {
      return error(new Error(`The path must start with '/' (${locator}).`));
    }

    const navigation = this._root.navigationContainer.ref;
    if (navigation === undefined) {
      return error(
        new Error(
          "Couldn't find a navigation object. Seems like navigation container is not configured yet.",
        ),
      );
    }

    const options = this._root.linkingOptionsProvider.linkingOptions;

    const state = (options?.getStateFromPath ?? getStateFromPath)(
      locator,
      options.config,
    );

    if (state) {
      let root = navigation as unknown as NavigationProp<ParamListBase>;
      let current;

      // Traverse up to get the root navigation
      while ((current = root.getParent())) {
        root = current;
      }

      const action = getActionFromState(state, options?.config);

      if (action !== undefined) {
        root.dispatch(action);
      } else {
        root.reset(state);
      }

      return success(undefined);
    } else {
      return error(
        new Error('Failed to parse the path to a navigation state.'),
      );
    }
  }
}
