import {AccountType} from '../../Auth';
import {useRoot} from '../../Root/hooks';
import {useCallback, useState} from 'react';
import {openLink, Target} from '../../Links';
import {observable} from 'mobx';
import HttpError from '../../../universal/features/api/socket/HttpError';

export enum AffiliateVariant {
  Promo,
  About,
  AppAbout,
  Dashboard,
}

const routes: Record<AffiliateVariant, string> = {
  [AffiliateVariant.Promo]: '/affiliate/farm_promo_codes',
  [AffiliateVariant.About]: '/app/farm/about',
  [AffiliateVariant.AppAbout]: '/app/about',
  [AffiliateVariant.Dashboard]: '/dashboard',
};

export default (
  goToAuthAndThenOpenAffiliate: () => void,
  affiliateVariant?: AffiliateVariant,
): {
  getAffiliateIsPending: () => boolean;
  safelyGoToAffiliate: () => Promise<void>;
} => {
  const [affiliateIsPendingBox] = useState(observable.box(false));
  const getAffiliateIsPending = useCallback(
    () => affiliateIsPendingBox.get(),
    [affiliateIsPendingBox],
  );
  const root = useRoot();
  const safelyGoToAffiliate = useCallback(async () => {
    const {socketApi, auth, configuration} = root;
    if (!auth.fernetToken) {
      return;
    }
    if (auth.accountType !== AccountType.Permanent) {
      goToAuthAndThenOpenAffiliate();
    } else {
      affiliateIsPendingBox.set(true);
      try {
        const response = await socketApi.getTemporaryToken({
          token: auth.fernetToken,
        });
        const {oauthIssuer} = configuration.values;
        const next = routes[affiliateVariant || AffiliateVariant.Dashboard];
        const loginUrl = `${oauthIssuer}login/token/${response.login}/?next=${next}`;
        await openLink(loginUrl, Target.Blank);
      } catch (raw) {
        if (raw instanceof HttpError && raw.details.status === 401) {
          await root.location.open(
            root.configuration.values.affiliateSuspendUrl,
          );
        }
      } finally {
        affiliateIsPendingBox.set(false);
      }
    }
  }, [
    root,
    goToAuthAndThenOpenAffiliate,
    affiliateIsPendingBox,
    affiliateVariant,
  ]);
  return {getAffiliateIsPending, safelyGoToAffiliate};
};
