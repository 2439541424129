import {Platform} from 'react-native';

export default abstract class DetectPlatform {
  static isAppleApp = Platform.OS === 'ios';
  static isAndroidApp = Platform.OS === 'android';
  static isWebApp = Platform.OS === 'web';

  static get isAppleSystem() {
    return this.isAppleApp || /ipad|iphone|ipod|mac/.test(this._UA);
  }

  static get isWindowsSystem() {
    return /win/.test(this._UA);
  }

  static get isAndroidSystem() {
    return this.isAndroidApp || /android/.test(this._UA);
  }

  private static _UA =
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.userAgent
      ? window.navigator.userAgent.toLowerCase()
      : '';
}
