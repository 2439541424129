import {ConfigurationValues} from './ConfigurationValues';
import common from './common';
import {
  MAIN_SOCKET_URL,
  HEALTH_CHECK_URL,
  API_URL,
  OAUTH_AUTHORIZATION_ENDPOINT,
  OAUTH_CLIENT_ID,
  OAUTH_ISSUER,
  OAUTH_REVOCATION_ENDPOINT,
  OAUTH_TOKEN_ENDPOINT,
  TOKEN_URL,
  IS_DEV,
} from '@env';

const values: ConfigurationValues = {
  ...common,
  mainSocketUrl: MAIN_SOCKET_URL,
  healthCheckUrl: HEALTH_CHECK_URL,
  apiUrl: API_URL,
  tokenUrl: TOKEN_URL,
  oauthIssuer: OAUTH_ISSUER,
  oauthClientId: OAUTH_CLIENT_ID,
  oauthAuthorizationEndpoint: OAUTH_AUTHORIZATION_ENDPOINT,
  oauthTokenEndpoint: OAUTH_TOKEN_ENDPOINT,
  oauthRevocationEndpoint: OAUTH_REVOCATION_ENDPOINT,
  isDev: IS_DEV === 'true',
};

export default values;
